import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    constructor(private toastController: ToastController) { }

    async showMessage(message: string) {
        const toast = await this.toastController.create({
            message,
            position: 'bottom',
            duration: 3
        });

        await toast.present();
    }
}
