import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SeasonService {

  apiURL = environment.apiURL;

  constructor(private httpClient: HttpClient) {}

  list(): Observable<any> {
    const now = new Date()
    let params = new HttpParams()
    params = params.append('include[]', 'categories.*')
    params = params.append('include[]', 'categories.config.*')
    params = params.append('include[]', 'categories.category.*')
    params = params.append('include[]', 'activity.*')
    params = params.append('filter{end_date.gte}', `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()}`)
    params = params.append('filter{start_date.lte}', `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()}`)
    params = params.append('per_page', '50')

    return this.httpClient.get<any>(`${this.apiURL}/ownership/v3/seasons/`, {
      params
    })
  }
}
