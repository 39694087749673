<ng-container *ngIf="relations$ | async as roles">
  <ion-list *ngIf="roles.length > 0">

    <ion-list-header>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap">
          Bytt bruker
          <p>
            <small>Listen under inneholder andre brukere eller klubber du har rettigheter til å styre</small>
          </p>
        </ion-label>
      </ion-item>
    </ion-list-header>

    <ion-item *ngFor="let role of roles" lines="none">
      <ion-label>
        {{ role.entity.name }}
        <p>
          {{ role.role }}
        </p>
      </ion-label>
      <ion-button (click)="changeRole(role)" fill="outline" slot="end">
        Bytt
      </ion-button>
    </ion-item>
  </ion-list>
</ng-container>