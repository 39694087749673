import { Component, OnInit } from '@angular/core';
import {UserService} from "../../services/user.service";
import {Observable, of} from "rxjs";
import {AuthService} from "../../modules/auth/services/auth.service";
import {LegalEntityRelation} from "../../models/legal-entity-relation";
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss'],
})
export class UserRolesComponent implements OnInit {

  relations$: Observable<LegalEntityRelation[]>;

  constructor(
      private userService: UserService,
      private authService: AuthService,
      private alertController: AlertController
  ) { }

  ngOnInit() {
    this.relations$ = this.userService.getRelations();
  }

  changeRole(relation: LegalEntityRelation) {
    this.alertController.create({
      header: 'Bytt bruker',
      message: 'Du er på vei til å bytte bruker. Dette vil logge ut deg og logge inn med den nye brukeren. Vil du fortsette?',
      buttons: [{
        text: 'Avbryt',
      }, {
        text: 'Fortsett',
        handler: () => {
          this.userService.loginAs(relation.entity).subscribe({
            next: async response => {
              await this.authService.setToken(response.token, '/forside');
              this.relations$ = of([]);
              this.alertController.create({
                header: 'Bytt bruker',
                message: 'Du har nå logget inn som ' + relation.entity.name,
                buttons: ['ok']
              }).then(alert => alert.present());
            },
            error: error => {

              if (error.error.statusCode === 403) {
                this.alertController.create({
                  header: 'Bytt bruker',
                  message: 'Du har ikke tilgang til å logge inn som ' + relation.entity.name,
                  buttons: ['ok']
                }).then(alert => alert.present());
              } else {
                alert('Noe gikk galt. Vennligst prøv igjen senere.');
              }
            }
          })
        }
      }]
    }).then(alert => alert.present());
  }

}
