import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FolderPage } from './folder.page';
import {VehicleRegistrationDetailFormComponent} from './vehicle-registration-detail-form/vehicle-registration-detail-form.component';
import {VehicleRegistrationCardComponent} from './vehicle-registration-card/vehicle-registration-card.component';

const routes: Routes = [
    {
        path: '',
        component: FolderPage
    }, {
        path: ':id',
        component: VehicleRegistrationDetailFormComponent
    }, {
        path: ':id/display',
        component: VehicleRegistrationCardComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FolderPageRoutingModule {}
