import { CompetitionNumber } from '../models/competition-number';
import { StartNumberFetchFilter } from '../models/start-number-fetch-filter';

export namespace StartNumberActions {
    export class Set {
        static readonly type = '[StartNumber] Set';

        constructor(public payload: CompetitionNumber[]) {}
    }

    export class FetchAll {
        static readonly type = '[StartNumber] FetchAll'
    }

    export class Purchase {
        static readonly type = '[StartNumber] Purchase'
        constructor(public payload: any) {}
    }

    export class FetchWithFilter {
        static readonly type = '[StartNumber] FetchWithFilter'
        constructor(public payload: StartNumberFetchFilter) {}
    }

    export class FetchMine {
        static readonly type = '[StartNumber] FetchMine' 
        constructor(public payload: any) {}
    }
}
