<ng-container *ngIf="browser$ | async as browser">

  <ng-container *ngIf="isUnsupported">
    <ion-card class="ion-padding-horizontal" color="danger">
      <ion-card-title>
        <h2>Vi støtter dessverre ikke {{ browser.name }} versjon {{ browser.version }}!</h2>
      </ion-card-title>
      
    
          
      <ion-card-content>
          <ion-list style="background-color: transparent;">
            <ion-list-header color="danger">
              <ion-label>
                <h2>Vi støtter følgende nettleser på desktop</h2>
              </ion-label>
            </ion-list-header>
            <ion-item>
              <ion-label>
                Chrome
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                Safari
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                Edge 79+
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                Firefox
              </ion-label>
            </ion-item>
          
            <ion-list-header color="danger">
              <ion-label>
                <h2>Og følgende på mobil</h2>
              </ion-label>
            </ion-list-header>
          
            <ion-item>
              <ion-label>
                Android 5.0+
              </ion-label>
            </ion-item>
            <ion-item>
              <ion-label>
                iOS 11+
              </ion-label>
            </ion-item>
          </ion-list>
        </ion-card-content>
    </ion-card>
  </ng-container>
</ng-container>
