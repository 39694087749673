import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VehicleRegistrationModel} from '../../models/vehicle-registration';

@Component({
    selector: 'app-vehicle-registration-card',
    templateUrl: './vehicle-registration-card.component.html',
    styleUrls: ['./vehicle-registration-card.component.scss'],
})
export class VehicleRegistrationCardComponent implements OnInit {

    items = [{
        label: 'Eier',
        value: 'Navn Navnesen'
    },  {
        label: 'Rammenummer',
        value: 'LAWTAB2033L567041'
    }, {
        label: 'Fabrikat',
        value: 'QIANJIANG MOTORCYCLES'
    }, {
        label: 'Typebetegnelse',
        value: 'Qianjiang Motorcycles'
    }, {
        label: 'Aktiviteter',
        value: 'Motocross'
    }, {
        label: 'Årsmodell',
        value: '2020'
    }, {
        label: 'Skiltnummer',
        value: 'AAA123'
    }, {
        label: 'Motornummer',
        value: ''
    }, {
        label: 'Startnummer',
        value: '33'
    }];

    title = 'app';
    elementType = 'url';
    value = JSON.stringify(this.items);

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {}

    onPrint() {
        print();
    }

    item: VehicleRegistrationModel;

    /*
    activities: [{…}]
created: "2020-06-19T09:17:35.073376Z"
customData: {}
customFrameNumber: false
engineNumber: ""
frameNumber: "LAWTAB2033L567041"
id: "808b19f9-7c81-445d-a338-f2e45c57eb3a"
insurance: "NO"
licensePlateNumber: true
manufacturer: {id: "29feac40-176a-4a92-8c1b-96a89407e4aa", created: "2020-06-19T09:48:05.060630Z", modified: "2020-06-19T09:48:05.060657Z", name: "QIANJIANG MOTORCYCLES"}
modified: "2020-06-19T09:48:27.317029Z"
owner: "ffcd01af-2d3e-4053-9438-2dd294bfd5d0"
registrationDate: null
state: "A"
typeDesignation: {id: "9144d4f5-dabf-4cf4-8025-c37a56a9a18f", created: "2020-06-19T09:48:05.070017Z", modified: "2020-06-19T09:48:05.070044Z", name: "Qianjiang Motorcycles", customData: {…}, …}
typeDesignationName: ""
vehicle: "cebcfca6-e3ac-437a-9119-b10dabfe6737"
yearModel: "2003"
     */

    /*



  vehicle:
engineNumber: ""
frameNumber: "LAWTAB2033L567041"
id: "cebcfca6-e3ac-437a-9119-b10dabfe6737"
licensePlateNumber: ""
manufacturer:
created: "2020-06-19T09:48:05.060630Z"
id: "29feac40-176a-4a92-8c1b-96a89407e4aa"
modified: "2020-06-19T09:48:05.060657Z"
name: "QIANJIANG MOTORCYCLES"
__proto__: Object
typeDesignation:
created: "2020-06-19T09:48:05.070017Z"
customData: {}
id: "9144d4f5-dabf-4cf4-8025-c37a56a9a18f"
manufacturer: "29feac40-176a-4a92-8c1b-96a89407e4aa"
modified: "2020-06-19T09:48:05.070044Z"
name: "Qianjiang Motorcycles"
__proto__: Object
yearModel: "2003"


   */
    ionViewWillEnter() {
        const state = history.state as { item: VehicleRegistrationModel };
        if (state.item) {
            const item = state.item;
            const v = item.vehicle;

            if (!item || !v) {
                // TODO: report to sentry
            }
            this.items = [{
                label: 'Eier',
                value: item.owner.name
            },  {
                label: 'Rammenummer*',
                value: v?.frameNumber
            }, {
                label: 'Fabrikat',
                value: v?.manufacturer?.name
            }, {
                label: 'Typebetegnelse',
                value: v?.typeDesignation?.name
            }, {
                label: 'Aktiviteter',
                value: item.activities.map(x => x.name).join(',')
            }, {
                label: 'Årsmodell',
                value: v?.yearModel
            }, {
                label: 'Motornummer',
                value: v?.engineNumber
            }, {
                label: 'Startnummer',
                value: item.startNumbers.map(sn => `${sn.number} - ${sn.seasonCategory.season.name } ${sn.seasonCategory.season.activity.name} ${sn.seasonCategory.category.name}`).join('<br/>')
            }, {
                label: 'Førstegangsregisterings dato',
                value: item.firstRegistrationDate
            }, {
                    label: 'Omregistrerings dato',
                    value: item.registrationDate
            }, {
                label: 'Elektrisk',
                value: item.isElectric && `Ja - ${this.getElectricTypeVerbose(item.electricType)}` || 'Nei'
            }];
        } else {
            this.router.navigate(['../../'], { relativeTo: this.route });
        }
    }

    getElectricTypeVerbose(electricType: string) {
        if (electricType === 'M') {
            return 'Moped (0-4kw)'
        } else if (electricType === 'LM') {
            return 'Lett motorsykkel (4-11kw)'
        } else if (electricType === 'TM') {
            return 'Tung motorsykkel (11+kw)'
        } else {
            return 'Ukjent - kontakt support'
        }
    }
}
