import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthSelectors } from './store/auth.selectors';
import { AuthModule } from './auth.module';

@Injectable({
  providedIn: AuthModule
})
export class AuthGuard implements CanActivate {

  constructor(private store: Store, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isAuthenticated = this.store.selectSnapshot(AuthSelectors.isAuthenticated)

    if (!isAuthenticated) {
      this.router.navigate(['/login'])
    }

    return isAuthenticated;
  }
  
}
