<ion-header>
    <ion-toolbar>
        <ion-title>
            Betal for registrering - 100kr
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content>
    <form>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label position="floating">Kortnummer</ion-label>
                        <ion-input></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label position="floating">Utløpsdato</ion-label>
                        <ion-input></ion-input>
                    </ion-item>
                </ion-col>
                <ion-col>
                    <ion-item>
                        <ion-label position="floating">CSV</ion-label>
                        <ion-input></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item button (click)="onDismiss()" color="primary" lines="none">
                        <ion-label>Betal</ion-label>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>
