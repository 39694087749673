import {ErrorHandler, NgModule, Injectable} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HttpClientModule, HttpErrorResponse, HTTP_INTERCEPTORS} from '@angular/common/http';

import * as Sentry from "@sentry/angular";
//import * as Sentry from "@sentry/browser";

import {environment} from '../environments/environment';
import {FolderPageModule} from './folder/folder.module';
import {NgxsModule} from '@ngxs/store';
import {VehicleRegistrationState} from './state/vehicle-registration.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import {SeasonState} from "./state/season-state";
import { StartNumberState } from './state/start-number.state';
import { IonicStorageModule } from '@ionic/storage';
import { AuthInterceptor } from './services/auth.interceptor';
import { StoreModule } from './store';
import { AuthModule } from './modules/auth/auth.module';
import { AuthState } from './modules/auth/store/auth.state';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { VERSION } from './utils/version';
import SentryRRWeb from "@sentry/rrweb";
import { getCurrentHub, addGlobalEventProcessor } from '@sentry/browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Integrations } from "@sentry/tracing";

import { hash } from 'git-version.json';
import { UserAgentState } from './state/user-agent.state';
import { isUnsupportedBrowser } from './browser-warning/browser-warning.component';
import {UserRolesComponent} from "./components/user-roles/user-roles.component";
var parser = require('ua-parser-js');

Sentry.init({
    dsn: 'https://3d17447fa9534f38a1f8573f41c4f4c0@glitchtip.nor1.se/4',
    // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
    // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
    // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
    integrations: [
        //new SentryRRWeb({}),
        new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
        }),
        new Integrations.BrowserTracing()
        //  new SentryRRWeb()
    ],
    release: hash,
    environment: environment.env,
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
        
        const error = hint.originalException as Error
        const browser = parser(navigator.userAgent).browser        

        if (isUnsupportedBrowser(browser)) {
            console.log('Filter out event for unsupported browsers')
            return null
        }

        if (
            error &&
            error.message &&
            error.message.match(/blocked a frame with origin/i)
          ) {
            if (browser.name === 'Facebook') {
                return null
            }
          }    
        
        return event;
      },
});

// Sentry.setTag("rrweb.active", hasReplays ? "yes" : "no");
//Sentry.setTag("rrweb.active", "yes");
Sentry.setTag('appVersion', VERSION)



@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {}

    extractError(error: any) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    handleError(error) {

        console.log('Global error handler', error);
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;

        if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
            return
        }
        
        const extractedError = this.extractError(error) || 'Handled unknown error';

        // Capture handled exception and send it to Sentry.
        const eventId = Sentry.captureException(extractedError);

        // When in development mode, log the error to console for immediate feedback.
        if (!environment.production) {
            console.error(extractedError);
        }

        // Optionally show user dialog to provide details on what happened.
        if (!(error.status && (error.status === 403 || error.status === 401))) {
            Sentry.showReportDialog({ eventId });
        }
    }
}

@NgModule({
    declarations: [AppComponent, UserRolesComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        FolderPageModule,
        //StoreModule,
        NgxsModule.forRoot([
            VehicleRegistrationState,
            SeasonState,
            StartNumberState,
            AuthState,
            UserAgentState,
        ], {
            developmentMode: !environment.production
        }),
        
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        IonicStorageModule.forRoot(),
        AuthModule,
        LoggerModule.forRoot({
            level:environment.logLevel,
            serverLogLevel: environment.serverLogLevel,
            disableConsoleLogging: false,
            enableSourceMaps: true
          }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: ErrorHandler, useClass: SentryErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
