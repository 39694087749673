import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-vehicle-registration-card-row',
    templateUrl: './vehicle-registration-card-row.component.html',
    styleUrls: ['./vehicle-registration-card-row.component.scss'],
})
export class VehicleRegistrationCardRowComponent implements OnInit {

    @Input() label: string;
    @Input() value: string;

    constructor() { }

    ngOnInit() {}

}
