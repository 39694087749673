import { Injectable } from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    loaders = [];
    constructor(private loadingController: LoadingController) { }

    async presentLoader(message: string) {
        const loading = await this.loadingController.create({
            message
        });
        await loading.present();

        this.loaders.push(loading);
        return loading;
    }

    async dismissAll() {
        await Promise.all(this.loaders.map(l => l.dismiss()));
        this.loaders = [];
    }
}
