import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {

  enableVehicleRegistrationPayment = false
  
  constructor() { 
    this.enableVehicleRegistrationPayment = moment().diff(moment('2021-02-01'), 'days', true) >= 0
  }

  
}
