import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { FolderPageRoutingModule } from './folder-routing.module';

import { FolderPage } from './folder.page';
import {VehicleRegistrationDetailFormComponent} from './vehicle-registration-detail-form/vehicle-registration-detail-form.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MomentModule} from 'ngx-moment';
import {VehicleRegistrationCardRowComponent} from './vehicle-registration-card-row/vehicle-registration-card-row.component';
import {BrowserModule} from '@angular/platform-browser';
import {VehicleRegistrationCardComponent} from './vehicle-registration-card/vehicle-registration-card.component';
import {NgxQRCodeModule} from 'ngx-qrcode2';
import {FrameNumberFormComponent} from './frame-number-form/frame-number-form.component';
import {PaymentModalComponent} from './payment-modal/payment-modal.component';
import { Base64encodePipe } from '../base64encode.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        FolderPageRoutingModule,
        ReactiveFormsModule,
        NgSelectModule,
        MomentModule,
        NgxQRCodeModule
    ],
    declarations: [FolderPage, VehicleRegistrationDetailFormComponent, VehicleRegistrationCardComponent,  VehicleRegistrationCardRowComponent, FrameNumberFormComponent, PaymentModalComponent, Base64encodePipe]
})
export class FolderPageModule {}
