import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '../models/user';
import { AuthSelectors } from '../modules/auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import {map} from "rxjs/operators";
import {LegalEntityRelation} from "../models/legal-entity-relation";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiURL = environment.apiURL;

  constructor(private httpClient: HttpClient, private store: Store) { }

  getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }

  getUser(): Observable<UserModel> {
    return this.httpClient.get<UserModel>(`${this.apiURL}/identity/profile/`, {
      headers: {
          Authorization: 'Bearer ' + this.store.selectSnapshot(AuthSelectors.token)
      }
    })
  }

    getRelations(): Observable<LegalEntityRelation[]> {
      return this.httpClient.get<LegalEntityRelation[]>(`${this.apiURL}/bi/vehicle-registry-app/roles/`, {
          headers: {
              Authorization: 'Bearer ' + this.store.selectSnapshot(AuthSelectors.token)
          }
      }).pipe(
          map(relations => relations.map(relation => ({
                ...relation,
              role: relation.role.replace('NIF', '').trim()
          }))));
  }

  loginAs(entity): Observable<{token: string}> {
      return this.httpClient.get<{ token: string }>(`${this.apiURL}/bi/vehicle-registry-app/login-as/${entity.id}`, {
          headers: {
              Authorization: 'Bearer ' + this.store.selectSnapshot(AuthSelectors.token)
          }
      })
  }
}
