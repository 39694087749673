import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {ListResponse} from '../models/list-response';
import {ActivityCategory} from '../models/activity-category';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CompetitionNumber, CompetitionNumberRequest} from '../models/competition-number';
import {MessageService} from './message.service';
import { StartNumberFetchFilter } from '../models/start-number-fetch-filter';
import { map } from 'rxjs/operators';
import { StartNumberListResponse } from '../models/start-number-list-response.model';

@Injectable({
    providedIn: 'root'
})
export class CompetitionNumberService {
    apiURL = environment.apiURL;
    reservedNumbers: CompetitionNumber[] = [];

    constructor(private httpClient: HttpClient, private messageService: MessageService) { }

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    clear() {
        this.reservedNumbers = [];
    }

    getActivityCategories(): Observable<ListResponse<ActivityCategory>> {
        return this.httpClient.get<ListResponse<ActivityCategory>>(`${this.apiURL}/ownership/activity-categories/`);
    }

    getCompetitionNumbers(seasonIds = []): Observable<ListResponse<CompetitionNumber>> {
        let params = new HttpParams()
        seasonIds.forEach(s => params.append('season_id', s))
        params.append('page_size', '1000')
        console.log('ge tcom p number', seasonIds, params)
        return this.httpClient.get<ListResponse<CompetitionNumber>>(`${this.apiURL}/ownership/start-numbers/`)
    }

    getStartNumbers(since=null, version='v3'): Observable<any> {

        const params = {
            per_page: 1000
        } as any

        if (since) {
            params['filter{modified.gte}'] = since
        }

        return this.httpClient.get<any>(`${this.apiURL}/ownership/${version}/start-numbers/`, {
            params
        })
        .pipe(
            map(response => ({
            ...response,
            startNumbers: response.startNumbers.map(sn => ({
                ...sn,
                owner: {
                    id: sn.owner
                } as any
            }))
        })),
        )
    }

    getStartNumbersV2(config = {} as any): Observable<any> {

        config.version = config.version || 'v3'

        const params = {
            per_page: 1000
        } as any

        if (config.since) {
            params['filter{modified.gte}'] = config.since
        }

        if (config.user) {
            params['filter{owner}'] = config.user.id
        }

        if (config.seasonCategories) {
            config.seasonCategories.forEach(sc => {
                params['filter{season_category.in}'] = sc.id
            })
        }

        if (config.state) {
            params['filter{state}'] = config.state
        }

        return this.httpClient.get<any>(`${this.apiURL}/ownership/${config.version}/start-numbers/`, {
            params
        })
        .pipe(
            map(response => ({
            ...response,
            startNumbers: response.startNumbers.map(sn => ({
                ...sn,
                owner: {
                    id: sn.owner
                } as any
            }))
        })),
        )
    }

    getCompetitionNumbersWithFilter(filter: StartNumberFetchFilter) {
        let params = new HttpParams()

        if (filter.state) {
            params.append('state', filter.state)
        }
        return this.httpClient.get<ListResponse<CompetitionNumber>>(`${this.apiURL}/ownership/start-numbers/`)
    }

    postCompetitionNumbers(data: any): Observable<CompetitionNumberRequest> {
        return this.httpClient.post<CompetitionNumberRequest>(`${this.apiURL}/ownership/start-numbers/purchase/`, data);
    }

    initPurchase(data: any): Observable<any> {
        data['cancel_url'] = `${document.location.protocol + '//' + document.location.host}/competition-number?cancel=true`
        data['success_url'] = `${document.location.protocol + '//' + document.location.host}/competition-number?success=true`
        //data['success_url'] = `${document.location.protocol + '//' + document.location.host}/folder/vognkort?success=true`
        return this.httpClient.post<any>(`${this.apiURL}/ownership/start-number-purchase/`, data)
    }

    directPurchase(session): Observable<any> {
        const params = new HttpParams().append('session', session)
        return this.httpClient.get<any>(`${this.apiURL}/ownership/start-number-purchase/`, {
            params
        })
    }

    addToCart(competitionNumber: CompetitionNumber) {
        //console.log('add to cart', competitionNumber, this.hasCompetitionNumberInCategory(competitionNumber))
        if (!this.hasCompetitionNumber(competitionNumber)) {

            if (this.hasCompetitionNumberInCategory(competitionNumber)) {
                const rv = this.reservedNumbers.find(cn => this.compareCompetitionNumberSeasonCategory(cn, competitionNumber))
                this.removeFromCart(rv)
            }

            //console.log('PUSH TO CART', competitionNumber)
            this.reservedNumbers.push(competitionNumber);
            document.getElementById('popoverButton').click()
            // this.messageService.showMessage(`${competitionNumber.activityCategory.activity.name} ${competitionNumber.activityCategory.category.name} ${competitionNumber.number} added to cart`);
            return true;
        }
    }

    removeFromCart(competitionNumber: CompetitionNumber) {        
        if (this.hasCompetitionNumber(competitionNumber)) {
            this.reservedNumbers = this.reservedNumbers.filter(cn => !this.compareCompetitionNumbers(cn, competitionNumber))
            return true;
        }
    }

    hasCompetitionNumber(competitionNumber: CompetitionNumber): boolean {
        return this.reservedNumbers.find(cn => this.compareCompetitionNumbers(cn, competitionNumber)) !== undefined;
    }

    hasCompetitionNumberInCategory(competitionNumber: CompetitionNumber) {
        return this.reservedNumbers.find(cn => this.compareCompetitionNumberSeasonCategory(cn, competitionNumber)) !== undefined
    }

    compareCompetitionNumbers(cn1: CompetitionNumber, cn2: CompetitionNumber) {
        return cn1.seasonCategory.id === cn2.seasonCategory.id && cn1.number === cn2.number;
    }

    compareCompetitionNumberSeasonCategory(cn1: CompetitionNumber, cn2: CompetitionNumber) {
        return cn1.seasonCategory.id === cn2.seasonCategory.id;
    }
}
    