import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthSelectors } from '../modules/auth/store/auth.selectors';
import { VERSION } from '../utils/version';
import { UserAgentSelectors } from '../state/user-agent.selectors';
import { hri } from 'human-readable-ids'
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected router: Router, private store: Store, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let identifier = localStorage.getItem('identifier')

    if (!identifier) {
      identifier = hri.random()
      localStorage.setItem('identifier', identifier)
    }
    const user = this.authService.getUser()

    if (user) {
      let tmp = localStorage.getItem('identifier:' + user.id)

      if (tmp) {
        identifier = tmp
      } else {
        localStorage.setItem('identifier:' + user.id, identifier)
      }
    }

    return this.store.select(UserAgentSelectors.browser).pipe(
      switchMap(browser => {
        let authReq = request.clone({
          setParams: {
            appVersion: VERSION,
            human: identifier,
            ...(browser || {})
          }
        });
    
        if (authReq.url.indexOf('ownership') >= 0 || authReq.url.indexOf('identity/logout4') >= 0) {
          authReq = authReq.clone({
            setHeaders: {
              Authorization: `Bearer ${this.store.selectSnapshot(AuthSelectors.token)}`
            }
          });  
        }
         return next.handle(authReq).pipe(
           tap((event: HttpEvent<any>) => {
             if (event instanceof HttpResponse) {
    
             }
           }, (err: any) => {
             if (err instanceof HttpErrorResponse) {
               if ((err.status === 403 || err.status === 401) && err.url.indexOf('bi/vehicle-registry-app/login-as') < 0) {
                 this.router.navigate(['/logged-out']);
               }
           }})
       );
      })
    )
  }
}