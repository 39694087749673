import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ListResponse} from '../models/list-response';
import {Activity} from '../models/activity';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    apiURL = environment.apiURL;

    constructor(private httpClient: HttpClient) { }

    activities(): Observable<ListResponse<Activity>> {
        return this.httpClient.get<ListResponse<Activity>>(`${this.apiURL}/ownership/activities/`);
    }
}
