import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './store/auth.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthRoutingModule } from './auth-routing.module';


@NgModule({
  declarations: [],
  imports: [
    AuthRoutingModule,
    CommonModule,
    NgxsModule.forFeature([AuthState]),
    NgxsStoragePluginModule.forRoot({
      key: 'auth.token'
    })
  ],
  exports: []
})
export class AuthModule { }
