import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {VERSION} from './utils/version';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';
import { UserModel } from './models/user';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';
import { AuthSelectors } from './modules/auth/store/auth.selectors';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { AuthActions } from './modules/auth/store/auth.actions';
import * as Sentry from "@sentry/angular";
//import * as Sentry from "@sentry/browser";
import { NGXLogger } from 'ngx-logger';
import { UserAgentActions } from './state/user-agent.actions';
var parser = require('ua-parser-js');


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;
    public env = environment.env
    public user$: Observable<UserModel>;
    public appPages = [
        {
            title: 'Forside',
            url: '/forside',
            icon: 'home'
        },
        {
            title: 'Skjema',
            url: '/folder/søknader',
            icon: 'add-circle',
        },
        {
            title: 'Kjøretøybevis',
            url: '/folder/vognkort',
            icon: 'reader',
        },
        /*
        {
            title: 'Startnummer',
            url: '/start-number',
            icon: 'trophy'
        },
        */
        {
            title: 'Startnummer ',
            url: '/competition-number',
            icon: 'trophy'
        },
        {
            title: 'Logg ut',
            url: '/logged-out', 
            //url: `${environment.apiURL}/identity/logout2/`,
            //url: `${environment.apiURL}/identity/disconnect/buypass/?next=${encodeURI(`${window.location.protocol}//${window.location.host}/logged-out`)}`,
            //external: true,
            icon: 'log-out'
        }
    ];
    public unauthenticatedPages = [
        {
            title: 'Logg på',
            url: '/login',
            icon: 'log-in'
        }
    ]
    public labels = []; // 'Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    version = VERSION;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private authService: AuthService,
        private userService: UserService,
        private store: Store,
        private router: Router,
        private logger: NGXLogger
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    ngOnInit() {
        this.store.dispatch(new UserAgentActions.Set(parser(navigator.userAgent)))
        
        const path = window.location.pathname.split('folder/')[1];
        if (path !== undefined) {
            this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
        }
        this.user$ = this.authService.currentUser;

        this.store.select(AuthSelectors.state).subscribe(state => {
            const token = state.token
            if (token) {
                this.userService.getUser().subscribe(user => {
                    this.authService.setUser(user)

                    if (window.location.pathname.indexOf('login') > -1) {
                        if (state.location) {
                            this.router.navigate([state.location])
                        } else {
                            this.router.navigate(['/forside'])
                        }
                    }
                }, err => {
                    this.store.dispatch(new AuthActions.Logout())
                    this.authService.setUser(null)
                })
            } else {
                // this.router.navigate(['/login'])
            }
        })
    }

    triggerSentry() {
        // @ts-ignore
        myUndefinedFunction();
    }
}
