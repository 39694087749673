import { State, Action, StateContext } from "@ngxs/store";
import { Injectable } from '@angular/core';
import { AuthStateModel } from './auth-state.model';
import { AuthActions } from './auth.actions';
import { AuthService } from 'src/app/services/auth.service';

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        token: null,
        location: null
    }
})
@Injectable()
export class AuthState {

    constructor(private authService: AuthService) {}

    @Action(AuthActions.Login)
    login({ patchState }, action: AuthActions.Login) {
        patchState({
            token: action.payload.token,
            location: action.payload.location
        });
    }

    @Action(AuthActions.Logout)
    async logout({ setState }) {
        setState({})
    }
}