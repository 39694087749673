import { CompetitionNumber } from "../models/competition-number";
import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, Store, createSelector } from '@ngxs/store';
import { CompetitionNumberService } from '../services/competition-number.service';
import { StartNumberActions } from '../actions/start-number.actions';
import { map, tap } from 'rxjs/operators';
import { StartNumberStateEnum } from '../models/start-number-state-enum';
import * as moment from 'moment';
import { createCssSelector } from '@angular/compiler/src/render3/view/template';

export class StartNumberStateModel {
    lastFetched: null | string
    lastFetchedMine: null | string
    entities: CompetitionNumber[];
}

@State<StartNumberStateModel>({
    name: 'startNumbers',
    defaults: {
        lastFetched: null,
        lastFetchedMine: null,
        entities: []
    }
})
@Injectable()
export class StartNumberState {
    constructor(private competitionNumberService: CompetitionNumberService) { }

    @Selector()
    static all(state: StartNumberStateModel) {
        return state.entities
    }

    @Selector()
    static purchased(state: StartNumberStateModel) {
        return state.entities.filter(e => e.state === StartNumberStateEnum.PURCHASED)
    }

    @Selector()
    static my(state: StartNumberStateModel) {
        return state.entities
    }

    static myReserved(ownerId: string) {
        return createSelector([this], (state: StartNumberStateModel) => {
            return state.entities.filter(
                e => e.state === StartNumberStateEnum.RESERVED && e.owner.id === ownerId
            )
        })
    }

    static myPurchased(ownerId: string) {
        return createSelector([this], (state: StartNumberStateModel) => {
            return state.entities.filter(
                e => e.state === StartNumberStateEnum.PURCHASED && e.owner.id === ownerId
            )
        })
    }

    @Action(StartNumberActions.Set)
    set({getState, patchState }, {payload}) {
        const state = getState();
        patchState({
            ...state,
            entities: payload
        })
    }

    @Action(StartNumberActions.FetchAll)
    fetchAll({ getState, setState }) {
        const state = getState()
        const lastFetched = state.lastFetched
        return this.competitionNumberService.getStartNumbers(lastFetched).pipe(
            map(response => response.startNumbers),
            tap(entities => {
                const state = getState();
                const result = Object.values([...state.entities, ...entities].reduce((result, {
                    id,
                    ...rest
                  }) => {
                    result[id] = {
                      ...(result[id] || {}),
                      id,
                      ...rest
                    };
                    return result;
                  }, {}));
                setState({
                    ...state,
                    entities: result,
                    lastFetched: moment.utc().toISOString()
                })
            })
        )
    }

    @Action(StartNumberActions.FetchMine)
    fetchMine({ getState, setState }, { payload }) {
        const state = getState()
        const lastFetchedMine = state.lastFetchedMine
        return this.competitionNumberService.getStartNumbersV2({ user: payload, since: lastFetchedMine }).pipe(
            map(response => response.startNumbers),
            tap(entities => {
                const state = getState();
                const result = Object.values([...state.entities, ...entities].reduce((result, {
                    id,
                    ...rest
                  }) => {
                    result[id] = {
                      ...(result[id] || {}),
                      id,
                      ...rest
                    };
                    return result;
                  }, {}));
                setState({
                    ...state,
                    entities: result,
                    lastFetchedMine: moment.utc().toISOString()
                })
            })
        )
    }

    @Action(StartNumberActions.FetchWithFilter)
    fetchWithFilter({ getState, setState }, { payload }) {
        return this.competitionNumberService.getCompetitionNumbersWithFilter(payload).pipe(
            map(response => response.results),
            tap(entities => {
                const state = getState()
                const result = Object.values([...state.entities, ...entities].reduce((result, {
                    id,
                    ...rest
                  }) => {
                    result[id] = {
                      ...(result[id] || {}),
                      id,
                      ...rest
                    };
                    return result;
                  }, {}));
                setState({
                    lastFetched: state.lastFetched,
                    entities: result
                })
            })
        )
    }

    @Action(StartNumberActions.Purchase)
    purchase(ctx: StateContext<StartNumberStateModel>, { payload }) {
        return ctx.setState({
            lastFetched: ctx.getState().lastFetched,
            lastFetchedMine: ctx.getState().lastFetchedMine,
            entities: ctx.getState().entities.map(sn => {
                if (sn.id === payload.sn1.id) {
                    console.log('yoyo', sn.id, payload.sn1, sn.isBought)
                    return ({
                        ...sn,
                        isBought: payload.sn2
                    })
                }
                return sn
            })
        })
      }
}