import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {VehicleRegistrationModel} from '../models/vehicle-registration';
import {ListResponse} from '../models/list-response';
import {Observable} from 'rxjs';
import {VehicleRegistrationUpdateRequest} from '../models/vehicle-registration-update-request';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VehicleRegistrationService {

    apiURL = environment.apiURL;

    constructor(private httpClient: HttpClient) {}

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    vehicleRegistrations(): Observable<ListResponse<VehicleRegistrationModel>> {
        return this.httpClient.get<ListResponse<VehicleRegistrationModel>>(`${this.apiURL}/ownership/vehicle-registrations/`, {
            withCredentials: true
        });
    }

    vehicleRegistration(id: string): Observable<VehicleRegistrationModel> {
        return this.httpClient.get<VehicleRegistrationModel>(`${this.apiURL}/ownership/vehicle-registrations/${id}/`);
    }

    updateVehicleRegistration(id: string, data: VehicleRegistrationUpdateRequest) {
        return this.httpClient.patch<VehicleRegistrationModel>(`${this.apiURL}/ownership/vehicle-registrations/${id}/`, data);
    }

    putVehicleRegistration(id: string, data: VehicleRegistrationUpdateRequest) {
        return this.httpClient.put<VehicleRegistrationModel>(`${this.apiURL}/ownership/vehicle-registrations/${id}/`, data);
    }

    removeVehicleRegistration(id: string) {
        return this.httpClient.delete<VehicleRegistrationModel>(`${this.apiURL}/ownership/vehicle-registrations/${id}/`)
    }

    validateFrameNumber(frameNumber: string) {
        return this.httpClient.get(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${frameNumber}?format=json`);
    }

    createVehicleRegistration(data: any): Observable<VehicleRegistrationModel> {
        console.log('createVehicleRegistration', data)
        return this.httpClient.post<VehicleRegistrationModel>(`${this.apiURL}/ownership/vehicle-registrations/`, data,);
    }

    initPurchase(data: any): Observable<any> {
        data['cancel_url'] = `${document.location.protocol + '//' + document.location.host}/folder/s%C3%B8knader?cancel=true`
        data['success_url'] = `${document.location.protocol + '//' + document.location.host}/folder/s%C3%B8knader?success=true`
        return this.httpClient.post<any>(`${this.apiURL}/ownership/vehicle-registration-purchase/`, data)
    }
}
