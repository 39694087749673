import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {VehicleRegistrationService} from '../services/vehicle-registration.service';
import {VehicleRegistrationModel} from '../models/vehicle-registration';
import {filter, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {LoaderService} from '../services/loader.service';
import {Select, Store} from '@ngxs/store';
import {VehicleRegistrationState} from '../state/vehicle-registration.state';
import { VehicleRegistrationActions } from '../actions/vehicle-registration.actions';
import {ModalController, AlertController} from '@ionic/angular';
import { environment } from 'src/environments/environment';
import {FrameNumberFormComponent} from './frame-number-form/frame-number-form.component';
import { StartNumberActions } from '../actions/start-number.actions';
import { AuthService } from '../services/auth.service';
import { loadStripe } from '@stripe/stripe-js';
import { FeatureFlagsService } from '../services/feature-flags.service';

@Component({
    selector: 'app-folder',
    templateUrl: './folder.page.html',
    styleUrls: ['./folder.page.scss'],
})
export class FolderPage implements OnInit {

    @Select(VehicleRegistrationState.approved) approvedRegistrations: Observable<VehicleRegistrationModel[]>;
    @Select(VehicleRegistrationState.denied) deniedRegistrations: Observable<VehicleRegistrationModel[]>;
    @Select(VehicleRegistrationState.partlyFilled) incompleteRegistrationss: Observable<VehicleRegistrationModel[]>;
    @Select(VehicleRegistrationState.getVehicleRegistrationList) allVehicleRegistrations: Observable<VehicleRegistrationModel[]>;
    @Select(VehicleRegistrationState.sent) sentVehicleRegistrations: Observable<VehicleRegistrationModel[]>;
    @Select(VehicleRegistrationState.paid) paidVehicleRegistrations: Observable<VehicleRegistrationModel[]>;

    public folder: string;
    showApprovedAppliations = false;

    constructor(private activatedRoute: ActivatedRoute, private vrService: VehicleRegistrationService, private loaderService: LoaderService, private router: Router, private store: Store, private modalCtrl: ModalController, private alertController: AlertController, private authService: AuthService, public featureFlagsService: FeatureFlagsService) { }
    
    

    ngOnInit() {
        this.store.dispatch(new VehicleRegistrationActions.FetchAll());

        this.authService.currentUser.pipe(
            filter(user => !!user),
            take(1)
        ).subscribe(user => {
            this.store.dispatch(new StartNumberActions.FetchMine(user));
        })
        
        this.folder = this.activatedRoute.snapshot.paramMap.get('id');
        this.showApprovedAppliations = this.folder === 'vognkort';

        this.activatedRoute.queryParamMap.subscribe(async queryParams => {
            const success = queryParams.get("success");
            const cancel = queryParams.get('cancel');

            if (success) {
                const alert = await this.alertController.create({      
                    message: 'Kvittering blir sendt til din epost.',
                    subHeader: 'Fullført kjøp!',
                    buttons: ['Ok']
                })
                await alert.present();
                this.router.navigate(
                    [], 
                    {
                      relativeTo: this.activatedRoute,
                      queryParams: {}, 
                      //queryParamsHandling: 'merge', // remove to replace all query params by provided
                    });
            }

            if (cancel) {
                const alert = await this.alertController.create({
                    message: 'Prøv på nytt',
                    subHeader: 'Noe gikk galt med kjøpet',
                    buttons: ['Ok']
                })
                await alert.present();
                this.router.navigate(
                    [], 
                    {
                      relativeTo: this.activatedRoute,
                      queryParams: {}, 
                      //queryParamsHandling: 'merge', // remove to replace all query params by provided
                    });
            }
            
         })
        // this.onSubmit();
    }

    async onSubmit() {

        /*

      export interface ModalOptions<T extends ComponentRef = ComponentRef> {
    component: T;
    componentProps?: ComponentProps<T>;
    presentingElement?: HTMLElement;
    showBackdrop?: boolean;
    backdropDismiss?: boolean;
    cssClass?: string | string[];
    delegate?: FrameworkDelegate;
    animated?: boolean;
    swipeToClose?: boolean;
    mode?: Mode;
    keyboardClose?: boolean;
    id?: string;
    enterAnimation?: AnimationBuilder;
    leaveAnimation?: AnimationBuilder;
}

       */
        const modal = await this.modalCtrl.create({
            component: FrameNumberFormComponent
        });

        await modal.present();
        const { data } = await modal.onDidDismiss(); 

        if (data?.id) {
            await this.router.navigate(['folder/søknader/', data?.id]);
        }

        /*

        const loader = await this.loaderService.presentLoader('Oppretter søknad');
        this.vrService.createVehicleRegistration()
            .subscribe(async vr => {
                await loader.dismiss();
                console.log(vr);
                await this.router.navigate([vr.id], { relativeTo: this.activatedRoute });
            }, async err => {
                await loader.dismiss();
                console.error(err);
            });
         */
    }

    async onRemove(vr) {
        const alert = await this.alertController.create({       
            header: 'Er du sikker?',
            message: 'Du er på vet til å fjerne en søknad for godt.',
            buttons: [
                {
                    text: 'Nei',
                    role: 'Cancel'
                }, {
                    text: 'Ja, fjern søknaden',
                    handler: () => {
                        this.store.dispatch(new VehicleRegistrationActions.Remove(vr));
                    }
                }
            ]
        })

        await alert.present();
    }

    async onPay(vr) {
        const loader = await this.loaderService.presentLoader("Vennligst vent...")

        try {
            const data = await this.vrService.initPurchase({
                'vehicle_registrations': [vr.id]
            }).pipe(take(1)).toPromise()
            await loader.dismiss()

            const stripePromise = loadStripe(environment.stripePublicKey);
            const stripe = await stripePromise;

            const result = await stripe.redirectToCheckout({
                sessionId: data.id,
            });

            if (result.error) {
                
            }
        } catch {
            await loader.dismiss()
            const alert = await this.alertController.create({
                
                message: 'Vi jobber med å rette feilen',
                header: 'Noe gikk veldig galt.',
                buttons: ['Ok']
            })

            await alert.present()
        }
    }
}
