import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { UserAgentSelectors } from '../state/user-agent.selectors';
import { Observable } from 'rxjs';
import { Browser } from '../state/user-agent-state.model';

@Component({
  selector: 'app-browser-warning',
  templateUrl: './browser-warning.component.html',
  styleUrls: ['./browser-warning.component.scss'],
})
export class BrowserWarningComponent implements OnInit {

  isUnsupported = false

  @Select(UserAgentSelectors.browser) browser$: Observable<Browser>;

  constructor() { }

  ngOnInit() {
    this.browser$.subscribe(browser => {
      this.isUnsupported = isUnsupportedBrowser(browser)
    })
  }
}

export function isUnsupportedBrowser(browser: Browser) {
  if (browser.name == 'Edge') {
    if (Number(browser.major) < 79) {
      return true
    }
  } else if (browser.name == 'IE') {
    return true
  } else if (browser.name === 'Mobile Safari') {
    if (Number(browser.major) < 11) {
      return true
    }
  } else if (browser.name === 'Android Browser') {
    if (Number(browser.major) < 5) {
      return true
    }
  }
  return false
}