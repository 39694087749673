import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {frameNumberValidator} from '../../utils/validators';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {VehicleRegistrationService} from '../../services/vehicle-registration.service';
import {Observable} from 'rxjs';
import {VehicleRegistrationActions} from '../../actions/vehicle-registration.actions';
import {Store} from '@ngxs/store';
import {ModalController} from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-frame-number-form',
    templateUrl: './frame-number-form.component.html',
    styleUrls: ['./frame-number-form.component.scss'],
})
export class FrameNumberFormComponent implements OnInit {

    environment = environment.env
    frameNumberForm: FormGroup;
    frameNumberLookup$: Observable<any>;
    hideVehicleInfo = true;
    error: any;
    isLoading = false
    lookupMessage: any;

    constructor(private fb: FormBuilder, private vrService: VehicleRegistrationService, private store: Store, private modalCtrl: ModalController) { }

    ngOnInit() {
        this.frameNumberForm = this.fb.group({
            frameNumber: ['', [Validators.required, Validators.minLength(3)]],
            vehicleInfo: this.fb.group({
                manufacturer: [],
                typeDesignation: [],
                yearModel: [],
                confirmed: [false]
            }),
        });

        this.frameNumberLookup$ = this.frameNumber.valueChanges.pipe(
            tap(console.log),
            filter(v => !v.valid),
            tap(() => {
                this.hideVehicleInfo = true;
                this.vehicleInfo.reset();
                this.confirmed.setValue(true);
                this.lookupMessage = ''
            }),
            filter(v => frameNumberValidator(this.frameNumber) === null),
            tap(() => this.setLoading(true)),
            switchMap((val: string) => this.vrService.validateFrameNumber(val)),
            filter((response: any) => response.Results?.length > 0),
            map(response => response.Results[0]),
            tap(console.log),
            filter(response => {
                if (response.ErrorCode === '4,14') {
                    this.lookupMessage = `Det er muligens en feil i rammenummeret, sjekk tegnene før og etter på det tegnet som er markert med !: ${response.SuggestedVIN}`
                    this.setLoading(false)
                    return false
                }
                return response
            }),
            map(({ Make, Model, ModelYear }) => ({
                manufacturer: Make.split(',').length > 1 ? Make.split(',')[0] : Make,
                typeDesignation: Model,
                yearModel: ModelYear
            })
            ),
            tap(v => {
                this.vehicleInfo.patchValue(v);
                this.hideVehicleInfo = false;
            }),
            tap(() => this.setLoading(false))
        );
    }

    setLoading(isLoading: boolean) {
        this.isLoading = isLoading
    }

    get frameNumber() {
        return this.frameNumberForm.controls.frameNumber;
    }

    get manufacturer() {
        return this.vehicleInfo.controls.manufacturer;
    }

    get typeDesignation() {
        return this.vehicleInfo.controls.typeDesignation;
    }

    get yearModel() {
        return this.vehicleInfo.controls.yearModel;
    }

    get vehicleInfo() {
        return this.frameNumberForm.controls.vehicleInfo as FormGroup;
    }

    get confirmed() {
        return this.vehicleInfo.controls.confirmed;
    }

    onSubmit() {
        if (this.frameNumberForm.valid) {

            const { frameNumber, vehicleInfo: { manufacturer, typeDesignation, yearModel, confirmed }} = this.frameNumberForm.value;
            let customFrameNumber = this.hideVehicleInfo

            if (!(manufacturer && typeDesignation && yearModel)) {
                customFrameNumber = true
            } else if (!confirmed) {
                customFrameNumber = true
            }

            const data = {
                frameNumber,
                manufacturerName: manufacturer,
                typeDesignationName: typeDesignation,
                yearModel,
                customFrameNumber
            };

            this.error = null;
            this.vrService.createVehicleRegistration(data)
                .subscribe(response => {
                    console.log('onSubmit', response)
                    this.store.dispatch(new VehicleRegistrationActions.Add(response));
                    console.log('onSubmit', 'dismiss')
                    this.modalCtrl.dismiss({
                        id: response.id
                    });
                }, err => {
                    console.error(err)
                    this.error = err
                });
        }
    }

    ionViewDidEnter() {
        // this.frameNumber.setValue('LAWTAB2033L567041');
    }

    onDismiss() {
        this.modalCtrl.dismiss();
    }
}
