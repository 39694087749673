import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ListResponse} from '../models/list-response';
import {TypeDesignation} from '../models/type-designation';
import {Manufacturer} from '../models/manufacturer';
import {ManufacturerGetOrCreateRequest} from '../models/manufacturer-get-or-create-request';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ManufacturerService {
    apiURL = environment.apiURL;

    constructor(private httpClient: HttpClient) { }

    getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    
    getManufacturers(): Observable<ListResponse<Manufacturer>> {
        return this.httpClient.get<ListResponse<Manufacturer>>(`${this.apiURL}/ownership/manufacturers/`);
    }

    getTypeDesignationByManufacturer(id: string): Observable<ListResponse<TypeDesignation>> {
        return this.httpClient.get<ListResponse<TypeDesignation>>(`${this.apiURL}/ownership/manufacturers/${id}/type-designations/`);
    }

    getOrCreateTypeDesignation(data: ManufacturerGetOrCreateRequest) {
        return this.httpClient.post<any>(`${this.apiURL}/ownership/manufacturers/get-or-create/`, data);
    }
}
