import { Injectable } from '@angular/core';
import { UserModel } from '../models/user';
import * as jwt_decode from 'jwt-decode';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthActions } from '../modules/auth/store/auth.actions';
import * as Sentry from "@sentry/angular";
//import * as Sentry from "@sentry/browser";

import { SeasonCategoryState } from '../store/season-category';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;

  private initialized = false;

  constructor(
    private storage: Storage,
    private store: Store,
    private httpClient: HttpClient
  ) {
      this.currentUserSubject = new BehaviorSubject<UserModel>(null);
      this.currentUser = this.currentUserSubject.asObservable()
  }

  setUser(user: UserModel) {
    console.log('set user stuff', user)
    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.name
      });
    } else {
      Sentry.setUser({})
    }
    
    this.currentUserSubject.next(user)
  }

  getUser(): UserModel {
    return this.currentUserSubject.value
  }

  /*
  login(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  async signup(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    try {
      const newUserCredential: firebase.auth.UserCredential = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      await this.firestore
        .doc(`userProfile/${newUserCredential.user.uid}`)
        .set({ email });
      return newUserCredential;
    } catch (error) {
      throw error;
    }
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  logout(): Promise<void> {
    return this.afAuth.signOut();
  }
  */

  logoutFromBackend() {

  }

  logoutFromBuyass() {

  }

  async logout() {
    //await this.store.dispatch(new AuthActions.Logout())
    //window.location.replace(`${environment.apiURL}/identity/logout/?next=${encodeURI(`${window.location.protocol}//${window.location.host}/logged-out`)}`)
      //window.location = 'http://localhost:8000/social/disconnect/buypass/?next=http%3A%2F%2Flocalhost%3A8100'
      console.log('ddoi ng th elogout????')
      try {
        //await this.httpClient.get(`${environment.apiURL}/identity/logout2/?next=${encodeURI(`${window.location.protocol}//${window.location.host}/forside`)}`).toPromise()
        window.location.replace(`${environment.authURL}/identity/logout/?next=${encodeURI(`${window.location.protocol}//${window.location.host}/forside`)}`)
      } catch {

      }
  }

  login() {
    console.log('ohuntohuntoehutnoehutoenhuntoheuneothuetohunoetehunotehu')
    //window.location.replace(`${environment.apiURL}/social/login/buypass/?next=${encodeURI(`${window.location.protocol}//${window.location.host}/forside`)}`)
    window.location.replace(`${environment.authURL}/identity/login/buypass/?next=${encodeURI(`${window.location.protocol}//${window.location.host}/forside`)}`)
    //const res = await this.httpClient.post('http://localhost:8000/api/login/social/buypass/', {}).toPromise()
    //console.log(res);
  }
}