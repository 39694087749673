import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/auth.guard';
import { DisableMenuGuard } from './guards/disable-menu.guard';

const routes: Routes = [
    {
        path: 'folder/:id',
        loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule),
        canActivate: [AuthGuard]
    },
  {
    path: 'competition-number',
    loadChildren: () => import('./competition-number/competition-number.module').then( m => m.CompetitionNumberPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logged-out',
    loadChildren: () => import('./logged-out/logged-out.module').then( m => m.LoggedOutPageModule),
    canActivate: [DisableMenuGuard],
    canDeactivate: [DisableMenuGuard]
  },
  {
    path: 'forside',
    loadChildren: () => import('./forside/forside.module').then( m => m.ForsidePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [DisableMenuGuard],
    canDeactivate: [DisableMenuGuard]
  },
  /*
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then( m => m.AuthModule)
  },*/
  {
    path: '**',
    redirectTo: 'login'
},/*
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then( m => m.NotFoundPageModule)
  },*/
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
