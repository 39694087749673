import { Injectable } from '@angular/core';
import { AuthModule } from '../auth.module';
import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { AuthActions } from '../store/auth.actions';

@Injectable({
  providedIn: AuthModule
})
export class AuthService {

  baseUri = environment.authURL;
  loginPath = '/identity/login/buypass/'
  callbackPath = '/login'

  constructor(private store: Store) { }

  private getLoginUrl() {
    const callbackUri = `${window.location.protocol}//${window.location.host}${this.callbackPath}`
    return `${this.baseUri}${this.loginPath}?redirect_uri=${encodeURI(callbackUri)}&next=yesno&prompt=login`
  }

  login() {
    const url = this.getLoginUrl();
    window.location.replace(url);
  }

  setToken(token: string, location='/forside') {
    this.store.dispatch(new AuthActions.Login({ token, location }))
  }
}
