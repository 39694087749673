<ion-row>
    <ion-col class="ion-no-padding header-col">
        <ion-item lines="none" color="primary">
            <ion-label>
                <ion-text style="font-weight: bold">{{label}}</ion-text>
            </ion-label>
        </ion-item>
    </ion-col>
    <ion-col class="ion-no-padding content-col">
        <ion-item>
            <ion-label>
                <div [innerHTML]="value"></div>

            </ion-label>
        </ion-item>
    </ion-col>
</ion-row>
