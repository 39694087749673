import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'base64encode'
})
export class Base64encodePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return btoa(JSON.stringify(value));
  }

}
