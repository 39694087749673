import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
    production: true,
    env: 'prod',
    //apiURL: 'https://msf.nor1.se',
    apiURL: 'https://regadmin.nmfsport.com',
    authURL: 'https://auth.nmfsport.com',
    stripePublicKey: 'pk_live_51HRwmiJ48uoMvvRMaVPgmBAbj979rH5CzB2PPJDDW2vjg5Z2IFHDqFOJn0KdlAyOxDgnYzEaaRsyB752BoRWPrvw00zuCtdaFa',
    logLevel: NgxLoggerLevel.WARN,
    serverLogLevel: NgxLoggerLevel.OFF
};
