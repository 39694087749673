import { VehicleRegistrationModel } from '../models/vehicle-registration';

export namespace VehicleRegistrationActions {
    export class Add {
        static readonly type = '[VehicleRegistration] Add';

        constructor(public payload: VehicleRegistrationModel) {}
    }

    export class FetchAll {
        static readonly type = '[VehicleRegistration] FetchAll';
    }

    export class Update {
        static readonly type = '[VehicleRegistration] Update';

        constructor(public payload: VehicleRegistrationModel) {}
    }

    export class SelectList {
        static readonly type = '[VehicleRegistration] SelectList';

        constructor(public payload: VehicleRegistrationModel[]) {
        }
    }

    export class Remove {
        static readonly type = '[VehicleRegistration] Remove';

        constructor(public payload: VehicleRegistrationModel) {}
    }
}
