import {SeasonModel} from "../models/season-model";

export namespace SeasonActions {
    export class Set {
        static readonly type = '[Season] Set';

        constructor(public payload: SeasonModel[]) {}
    }

    export class FetchAll {
        static readonly type = '[Season] FetchAll'
    }
}
