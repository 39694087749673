import { State, Action } from "@ngxs/store";
import { Injectable } from '@angular/core';
import { UserAgentStateModel } from './user-agent-state.model';
import { UserAgentActions } from './user-agent.actions';

@State<UserAgentStateModel>({
    name: 'userAgent',
    defaults: {
        browser: null
    }
})
@Injectable()
export class UserAgentState {

    constructor() {}

    @Action(UserAgentActions.Set)
    login({ setState }, action: UserAgentActions.Set) {
        setState(action.payload);
    }
}