<ion-header>
    <ion-toolbar>
        <ion-buttons slot="secondary">
            <ion-button (click)="onDismiss()">
                <ion-icon slot="icon-only" icon="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            Ny søknad
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <form [formGroup]="frameNumberForm">
        <ion-list>
            <ion-list-header>
                <h2>Skriv inn rammenummer</h2>
            </ion-list-header>
            <ion-item>
                <ion-label position="floating">Ramme nummer</ion-label>
                <ion-input formControlName="frameNumber"></ion-input>
            </ion-item>
            <ion-item lines="none">
                <ion-note>Tast inn minst 3 tegn</ion-note>
            </ion-item>
            <ion-item lines="none" *ngIf="lookupMessage">
                <ion-label style="white-space: normal;">
                    <h2>{{ lookupMessage }}</h2>
                </ion-label>

            </ion-item>
            <ng-container *ngIf="!hideVehicleInfo" formGroupName="vehicleInfo">
                <ng-container *ngIf="manufacturer || typeDesignation || yearModel">
                    <ion-item>
                        <ion-label>
                            <h2>Vi fant følgende informasjon fra ramme nummeret '{{ frameNumber.value }}'</h2>
                        </ion-label>
                    </ion-item>
                    <ion-item [hidden]="!manufacturer.value">
                        <ion-label position="floating">Fabrikat</ion-label>
                        <ion-input disabled formControlName="manufacturer"></ion-input>
                    </ion-item>
                    <ion-item [hidden]="!typeDesignation.value">
                        <ion-label position="floating">Type betegnelse</ion-label>
                        <ion-input disabled formControlName="typeDesignation"></ion-input>
                    </ion-item>
                    <ion-item [hidden]="!yearModel.value">
                        <ion-label position="floating">Årsmodel</ion-label>
                        <ion-input disabled formControlName="yearModel"></ion-input>
                    </ion-item>

                    <ion-item>
                        <ion-label>Informasjonen over er korrekt</ion-label>
                        <ion-checkbox [value]="true" formControlName="confirmed"></ion-checkbox>
                    </ion-item>
                </ng-container>

            </ng-container>

            <ng-container *ngIf="error">
            <ion-item lines="none">
                <ion-label color="danger">
                    Det skjedde en feil
                </ion-label>
            </ion-item>
            <ion-card>
                <pre>
{{ error?.error|json }}
            </pre>
            </ion-card>
            </ng-container>


            <ion-item-divider></ion-item-divider>

            <ion-item button color="primary" (click)="onSubmit()" [disabled]="isLoading">
                <ion-label>
                    Fortsett
                </ion-label>
            </ion-item>

            <div [hidden]="true">
                {{ frameNumberLookup$ | async }}
            </div>
        </ion-list>
    </form>

    <!--
    <ion-card>
        <ion-item>
            <ion-label>
                Valid: {{frameNumber.valid}} <br/>
                Errors: {{frameNumber.errors|json}}
            </ion-label>
        </ion-item>
    </ion-card>
    -->
    <ion-toolbar *ngIf="environment!='prod'">
        <ion-title class="ion-text-center">
            <ion-text style="font-size: 8px" color="medium">5Y4AJ19W06A300663</ion-text>
        </ion-title>
    </ion-toolbar>
</ion-content>

<ion-footer>

</ion-footer>

<!--
<ion-footer>
    <ion-toolbar>
        <ion-buttons>
            <ion-button>Avbryt</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
-->
