import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {

    constructor(private modalCtrl: ModalController) { }

    ngOnInit() {}

    async onDismiss() {
        await this.modalCtrl.dismiss();
    }

}
